<template>
	<div class="pupup_wrap bg-white mt-30 overflow-auto flex-column justify-space-between profile">
		<div class="header">
			<div class="top top_mafi ">
				<h2 class="page_tit">{{  $language.common.edit_profile }}</h2>
				<div class="util">
					<button
						class="close"
						@click="$emit('cancel')"
					>
						<i class="icon icon-close"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="container" style="overflow: auto">
			<div class="row ">
				<div class="size-px-14">프로필 이미지 또는 카드를 선택하세요</div>

				<div class="mt-30">
					<h2>이미지 첨부</h2>
					<div class="mt-10">
						<ul>
							<li class="profile-square cursor-pointer">
								<div class="inner">
									<label class="content cursor-pointer">
										<img :src="require('@/assets/image/@noimage.png')" class="width-100">
										<input_file_new @change="setUploadFile" accept="image/*" id="albumFile" />
									</label>
								</div>
							</li>

							<li class="profile-square cursor-pointer">
								<div
									class=" inner"
									@click="item.img_src == upload_img ? $set(item, 'img_src', '') : $set(item, 'img_src', upload_img)"
								>
									<div class="content ">
										<img
											v-if="upload_img"
											:src="$request.upload_url(upload_img)"
											class="width-100"
										/>
									</div>

									<template
										v-if="upload_img"
									>
										<v-icon
											v-if="item.img_src == upload_img"
											class="position-absolute color-blue bg-white"
											style="position: absolute; left: 5px; top: 5px; border-radius: 100%"
										>mdi-checkbox-marked-circle</v-icon>
										<v-icon
											v-else
											class="position-absolute color-white"
											style="position: absolute; left: 5px; top: 5px"
										>mdi-checkbox-blank-circle</v-icon>
									</template>
								</div>
							</li>
						</ul>
					</div>

					<div
						v-if="items_union.length > 0"
						class="mt-30"
					>
						<h2>유니온 카드</h2>
						<div class="mt-10">
							<ul>
								<li
									v-for="(card, index) in items_union"
									:key="'union_' + index"
									class="profile-square cursor-pointer"
									@click="setFile(card.nft_card_img_url)"
								>
									<div class="position-relative">
										<img
											:src="$request.upload_url(card.nft_card_img_url)"
											@error="$bus.$emit('onErrorImage', $event)"
											class="width-100"
										>

										<v-icon
											v-if="card.nft_card_img_url == item.img_src"
											@click="item=card"
											class="position-absolute color-blue"
											style="position: absolute; left: 5px; top: 5px; background-color: white; padding: 0; border-radius: 100%"
										>mdi-checkbox-marked-circle</v-icon>
										<v-icon
											v-else
											@click="item={}"
											class="position-absolute color-white"
											style="position: absolute; left: 5px; top: 5px"
										>mdi-checkbox-blank-circle</v-icon>
									</div>
								</li>
							</ul>
						</div>
					</div>

					<hr
						v-if="items_union.length > 0 && items_nft.length > 0"
						class="mt-20" style="border: 1px solid rgba(255, 255, 255, 0.1); "
					/>

					<div
						v-else-if="items_union.length == 0 && items_nft.length == 0"
						style="text-align: center; color: white"
					>
						<img :src="require('@/assets/image/intro_character3_none.png')" style="width: 100px"/>
						<p style="font-family: open sans; font-weight: 400; font-size: 1.4rem; color: white; margin-top: 14px">{{  $language.nft.nft_not_taken }}<p/>
					</div>

					<div
						v-if="items_nft.length > 0"
						class="mt-30"
					>
						<h2>MAFIA 카드</h2>
						<ul class="mt-10">
							<li
								v-for="(card, index) in items_nft"
								:key="'nft_' + index"
								class="profile-square"
								@click="setFile(card.cartl_nft_img_url)"
							>
								<div class="position-relative cursor-pointer">
									<img
										:src="$request.upload_url(card.cartl_nft_img_url)"
										@error="$bus.$emit('onErrorImage', $event)"
										class="width-100"
									>

									<v-icon
										v-if="card.cartl_nft_img_url == item.img_src"
										@click="item=card"
										class="position-absolute color-blue"
										style="position: absolute; left: 5px; top: 5px; background-color: white; padding: 0; border-radius: 100%"
									>mdi-checkbox-marked-circle</v-icon>
									<v-icon
										v-else
										@click="item={}"
										class="position-absolute color-white"
										style="position: absolute; left: 5px; top: 5px"
									>mdi-checkbox-blank-circle</v-icon>
								</div>
							</li>
						</ul>
					</div>
				</div>


			</div>
		</div>

		<div
			class="mt-auto btn_area "
		><button
			class="btn_fill_blue pa-10 size-px-16"
			:disabled="!item.img_src"

			@click="$emit('click', item)"
		>확인</button></div>
	</div>
</template>

<script>
	import input_file_new from '@/components/InputFileNew'

	export default {
		name: 'maifa1011'
		, props: ['user']
		, components: {input_file_new}
		, data: function(){
			return {
				program: {
					not_header: true
					, not_footer: true
				}
				, item: {
					img_src: ''
				}
				, items: []
				, upload_img: ''
				, items_union: []
				, items_nft: []
			}
		}
		, computed: {
		}
		, methods: {
			getData: async function(){
				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_nft
						, data: {
							member_number: this.user.member_number,
							page_number: this.$language.base.page_number,
							pagerecnum: 99
						}
						, type: true
					})
					if(result.success){

						this.items_nft = result.data.holding_cartl_nft_list
						this.items_union = result.data.holding_mafi_card_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, setUploadFile: function(e){
				this.upload_img = e

				this.$set(this.item, 'img_src', e)
				this.$set(this.item, 'nft_card_div_code', 'CM01000001')
			}
			,setFile: function(e){

				this.$set(this.item, 'img_src', e)
				this.$set(this.item, 'nft_card_div_code', 'CM01000002')
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>

<style>
	.profile .color-blue { color: var(--blue02) !important;}
	.profile .profile-square { display: inline-block; width: 31%; margin-bottom: 10px;}
	.profile .profile-square:not(:nth-child(3n)) { margin-right: calc(7%/2)}

	.profile .profile-square .inner { width: 100%; position: relative}
	.profile .profile-square .inner:after {
		display: block;
		content: "";
		padding-bottom: 100%;
	}
	.profile .profile-square .content { width: 100%; height: 100%; left: 0; top: 0; position: absolute; overflow: hidden; border-radius: 10px}

	.profile .position-relative { position: relative}

	.profile .on { border: 1px solid var(--blue02)}
	.profile img { border-radius: 10px}
</style>