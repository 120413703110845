<template>
	<div class="section_wrap pt-50">
		<div class="subindex_wrap">
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_mainbox">
						<div class="myphoto_area">
							<div class="myphoto">
								<img
									:src="$request.upload_url(item.img_src)" alt="item.file.name"
									@error="$bus.$emit('onErrorImage', $event)"
								>
							</div>
							<label class="img_set" @click="on1011">
							</label>
						</div>
						<div>
							<div class="nickname">
								<div class="bright_input">
									<label for="nickName">{{  $language.common.attach_image }}</label>
									<div class="input_utill">
										<input
											v-model="item.nickname"
											type="text" id="nickName" :placeholder="$language.nice.nickName_require" maxlength='10'
											disabled
										>
										<div
											v-show="false"
											class="input_utill_area"
										>
											<div class="btn_del">
												<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
												<button
													:class="{ active: item.nickname.length > 0 }"
													@click="item.nickname = ''"
												><span class="hide">{{  $language.common.close }}</span></button>
											</div>
											<span class="input_text_count" v-if="item.id">{{ item.nick.length }}/10</span>
										</div>
									</div>
								</div>
							</div>
							<div class="status_message">
								<div class="bright_input">
									<label for="statusMessage">{{  $language.common.statusMessage }}</label>
									<div class="input_utill">
										<input
											v-model="item.state_message"
											type="text" id="statusMessage" :placeholder="$language.nice.status_message_require" maxlength='20'>
										<div class="input_utill_area">
											<div class="btn_del">
												<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
												<button
													:class="{ active: item.state_message.length > 0 }"
													@click="item.state_message = ''"
												><span class="hide">{{  $language.common.close }}</span></button>
											</div>
											<!-- TODO : 텍스트 수 카운트 필요 -->
											<span class="input_text_count" v-if="item.id">{{ item.state_message.length }}/20</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_3 interest">
						<form action="">
							<label for="interest">{{ $language.interested.choice_interested }}</label>
							<div class="chk_block">
								<input
									v-model="item.fav"
									value="CM00400001"
									type="checkbox" id="inter_1" class="inter_music"
								><label for="inter_1">Music</label>
								<input
									v-model="item.fav"
									value="CM00400002" type="checkbox" id="inter_2" class="inter_art"
								><label for="inter_2">Art</label>
								<input
									v-model="item.fav"
									value="CM00400003" type="checkbox" id="inter_3" class="inter_fashion"
								><label for="inter_3">Fashion</label>
								<input
									v-model="item.fav"
									value="CM00400004" type="checkbox" id="inter_4" class="inter_influence"
								><label for="inter_4">Influence</label>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<mafia1011
			v-if="is_1011"

			:user="user"

			@click="setProfile"
			@cancel="is_1011 = !is_1011; $bus.$emit('onLoad', program)"

			class="position-fixed-full"
		></mafia1011>
	</div>
</template>

<script>
import Mafia1011 from "@/view/Mypage/mafia101-1";

export default {
	name: 'mafia101'
	, components: {Mafia1011}
	, props: ['Axios', 'callBack', 'user']
	, data: function(){
		return {
			program: {
				name: this.$language.common.edit_profile
				, title: this.$language.common.edit_profile
				, not_footer: true
				, type: 'mypage'
				, is_save: true
				, from: 'myPage'
				, callBack: {
					name: 'save'
				}
			}
			, item: {
				nickname: ''
				, state_message: ''
				, img_src: ''
				, nft_card_div_code: ''
			}
			, is_1011: false
			, item_main_nft: {
				profle_nft_card_img_url: ''
			}
			, upload_img: ''
		}
	}
	,methods: {
		getMyInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_my_info
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if(result.success){
					this.item = result.data
					this.$set(this.item, 'img_src', this.item.profle_nft_card_img_url)
					this.item.fav = []
					for(let i = 0; i < this.item.concern_sphere_list.length; i++){

						if(this.item.concern_sphere_list[i].concern_sphere_img_url.indexOf('off') > -1){
							this.item.fav[i] = ''
						}else{
							this.item.fav[i] = this.item.concern_sphere_list[i].concern_sphere_code
						}
					}
					this.is_1011 = false
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(this.$language)
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,updateProfile: async function(){

			this.$bus.$off('callBack')
			try{

				this.$bus.$emit('on', true)

				if(this.item.fav.length == 0){
					throw this.$language.interested.select_02
        }

				let concern_sphere_list = []
				for(let fav of this.item.fav ){
					if(fav){
						concern_sphere_list.push({ concern_sphere_code: fav})
					}
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_profile
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.item_main_nft.nft_card_number
						, state_message: this.item.state_message
						, concern_sphere_list: concern_sphere_list
						, img_url: this.upload_img
						, profle_img_upload_div_code: this.item.nft_card_div_code
					}
					, type: true
				})
				if(result.success){
					this.$bus.$emit('notify',  { type: 'success', message: this.$language.common.success })
			}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify',  { type: 'error', message: e})

			}finally {
				this.$bus.$emit('on', false)
				this.onCallback()
				this.upload_img = ''
			}
		}

		,getMainNft: async function(){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'get'
					,url: this.$api_url.api_path.get_main_nft
					,data: {
						type: 'my_card'
						, key: 'is_main'
						, val: true
					}
				})

				if(result.success){
					this.item_main_nft = result.data
					if(this.item_main_nft){
						this.item.img_src = this.item_main_nft.img_src
					}else{
						this.item.img_src = ''
					}
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setProfile: function(item){
			this.item.img_src = item.img_src
			this.upload_img = item.img_src
			this.$set(this.item, 'nft_card_div_code', item.nft_card_div_code)
			this.is_1011 = false

			this.$bus.$emit('onLoad', this.program)
		}
		, on1011: function(){
			this.is_1011 = true
		}
		, onCallback: function(){
			this.$bus.$off('callBack')
			this.$bus.$on('callBack', () => {
				this.updateProfile()
			})
		}
	}
	,async created() {
		this.$emit('onLoad', this.program)
		await this.getMyInfo()
	}
	,watch:{
		item: {
			deep: true
			, handler: function(){
				this.onCallback()
			}
		}
	}
}
</script>